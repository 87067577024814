<template>
  <div class="main">
    <div class="main__inner__nav">
      <nav-bar />
    </div>
    <div class="main__inner">
      <div class="pt-5">
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>
    <div class="main__inner__footer footer-text has-text-centered">
      © {{ year }} ТОО «Проманалитика»
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";

export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__inner {
    flex-grow: 1;
    background: linear-gradient(180deg, #b8c7e6, #e6eaf2 61px, #e6eaf2);

    &__nav {
      width: 100%;
      background: white;
      display: flex;
      flex-direction: column;
    }

    &__footer {
      padding: 24px 0 12px 24px;
    }
  }
}
//
//@media (max-width: 1024px) {
//  .container-set {
//    /* margin-left: 25px !important; */
//  }
//}

</style>
