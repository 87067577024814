import Vue from "vue"
import Vuex from "vuex"
import defaultAxios from "../api/axiosInstance"
import router from "../router"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navigationName: "Обзор",
    Data: [],
    Agents: [],
    MessagesData: [],
    ActiveMsg: [],
    ArchiveMsg: [],
    AllCompanies: [],
    LoginData: [],
    Predictions: [],
    Warlarm: [],
    SpectorsSelect: [],
    Spectors: [],
  },
  getters: {
    GetNavName(state) {
      return state.navigationName
    },
    GetData(state) {
      return state.Data
    },
    GetAgents(state) {
      return state.Agents
    },
    GetMessagesData(state) {
      return state.MessagesData
    },
    GetActiveMsg(state) {
      return state.ActiveMsg
    },
    GetArchiveMsg(state) {
      return state.ArchiveMsg
    },
    GetAllCompanies(state) {
      return state.AllCompanies
    },
    GetLoginAccess(state) {
      return state.LoginData
    },
    GetPredictions(state) {
      return state.Predictions
    },
    GetWarlarm(state) {
      return state.Warlarm
    },
    GetSpectorsSelect(state) {
      return state.SpectorsSelect
    },
    GetSpectors(state) {
      return state.Spectors
    },
  },
  mutations: {
    ChangeNavName(state, data) {
      state.navigationName = data
    },
    UpdateData(state, data) {
      state.Data = data
    },
    UpdateAgents(state, data) {
      state.Agents = data
    },
    UpdateMessages(state, data) {
      state.MessagesData = data
    },
    UpdateActiveMsg(state, data) {
      state.ActiveMsg = data
    },
    UpdateArchiveMsg(state, data) {
      state.ArchiveMsg = data
    },
    UpdateAllCompanies(state, data) {
      state.AllCompanies = data
    },
    Verification(state, data) {
      state.LoginData = data
      if (state.LoginData.data.user.role_id == 2) {
        router.push("/main")
      } else {
        router.push("/global")
      }

    },
    UpdatePredictions(state, data) {
      state.Predictions = data
    },
    UpdateTrendWarlarm(state, data) {
      state.Warlarm = data
    },
    UpdateSpectorsSelect(state, data) {
      state.SpectorsSelect = data
    },
    UpdateSpectors(state, data) {
      state.Spectors = data
    },
  },
  actions: {
    // async SignIn(ctx,data){
    //   await axios.post(`users/signin`,{
    //     login: data.login,
    //     password: data.password
    //   }).then(response => {
    //     localStorage.setItem('token', response.data.token);
    //     ctx.commit('Verification', response)
    //     axios.defaults.headers.common.token = `${response.data.token}`;
    //   })
    // },
    async FetchData(ctx) {
      await defaultAxios.get(`user/agents`).then(response => {
        let data = response?.data?.data || []
        ctx.commit("UpdateData", data)
      })
    },
    async DataOftrends(ctx, params) {
      await defaultAxios.post(`user/agents/${params.id}/trends`, params.date).then(response => {
        let data = response.data
        ctx.commit("UpdateAgents", data)
      })
    },
    async FetchMessages(ctx, params) {
      let data = []
      await defaultAxios.get(`user/agents/${params}/last-message`).then(response => {
        data = response.data.data
      })
      ctx.commit("UpdateMessages", data)
    },
    async FetchActiveMsg(ctx, params) {
      let data = []
      await defaultAxios.get(`user/agents/${params}/unread-messages/0/10`).then(response => {
        data = response.data
      })
      ctx.commit("UpdateActiveMsg", data)
    },
    async FetchArchiveMsg(ctx, params) {
      let data = []
      const { offset, id } = params
      await defaultAxios.get(`user/agents/${id}/messages/${offset}/10`).then(response => {
        data = response.data
      })
      ctx.commit("UpdateArchiveMsg", data)
    },
    async AllCompanies(ctx, params) {
      let data = []
      await defaultAxios.get(`manager/agents`).then(response => {
        data = response.data.data
      })
      ctx.commit("UpdateAllCompanies", data)
    },
    async FetchPredictions(ctx, param) {
      let data = []

      await defaultAxios.get(`user/agents/${param}/trend-predictions`).then(response => {
        data = response.data
      })

      ctx.commit("UpdatePredictions", data)
    },
    async FetchTrendWarlarm(ctx, params) {
      let data = []
      await defaultAxios.post(`user/agents/${params.id}/trend-warnings`, params.date).then(response => {
        data = response.data.data
        ctx.commit("UpdateTrendWarlarm", data)
      })
    },
    async FetchSpectorsSelect(ctx, params) {
      await defaultAxios.get(`user/agents/${params}/spectrum-types`).then(response => {
        ctx.commit("UpdateSpectorsSelect", response.data.data)
      })
    },
    async FetchSpector(ctx, params) {
      await defaultAxios.post(`user/agents/${params.id}/spectrums`, params.data).then(response => {
        ctx.commit("UpdateSpectors", response.data)
      })
    },

  },

})
