import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Login',
  //   meta:{layout:'empty',title:'Авторизация'},
  // component: () => import('../views/Login.vue'),
  // redirect: '/main',
  // },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "empty", title: "Авторизация" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "Main",
    meta: { layout: "main", title: "Главная страница" },
    component: () => import("../views/Main.vue"),
  },
  {
    path: "/device",
    name: "Device",
    meta: { layout: "main", title: "Устройство" },
    component: () => import("../views/Device.vue"),
    props: true,
    redirect: "/device/views/:device_id",
    children: [
      {
        path: "views/:device_id",
        meta: { layout: "main", title: "Обзор" },
        component: () => import("../components/Views.vue"),
        props: true,
      },
      {
        path: "spectors/:device_id",
        meta: { layout: "main", title: "Спектры" },
        component: () => import("../components/Spectors.vue"),
        props: true,
      },
      {
        path: "trends/vel/:device_id",
        name: "DeviceTrendsVel",
        meta: { layout: "main", title: "Тренды виброскорости", type: 'vel' },
        component: () => import("../components/Trends.vue"),
        props: true,
      },
      {
        path: "trends/acc/:device_id",
        name: "DeviceTrendsAcc",
        meta: { layout: "main", title: "Тренды виброускорения", type: 'acc' },
        component: () => import("../components/Trends.vue"),
        props: true,
      },
      // {
      //   path: "trends-spec/:device_id",
      //   meta: { layout: "main", title: "Спектры трендов" },
      //   component: () => import("../components/Trends-Spec.vue"),
      //   props: true,
      // },
      {
        path: "trends-spec/:device_id",
        meta: { layout: "main", title: "Спектры трендов" },
        component: () => import("../components/Trends-Spec-Old.vue"),
        props: true,
      },
      {
        path: "trends-oil/:device_id",
        meta: { layout: "main", title: "Тренды счетчиков частиц в масле" },
        component: () => import("../components/TrendsOil.vue"),
        props: true,
      },
      {
        path: "active-messages/:device_id",
        meta: { layout: "main", title: "Сообщения" },
        component: () => import("../components/Active-messages.vue"),
        props: true,
      },
      {
        path: "archive-messages/:device_id",
        meta: { layout: "main", title: "Архивные сообщения" },
        component: () => import("../components/Archive-messages.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/global",
    name: "Global",
    meta: { layout: "main", title: "Глобальный мониторинг" },
    component: () => import("../views/Global-Monitoring.vue"),
    redirect: "/global/all-companies",
    children: [
      {
        path: "all-companies",
        meta: { layout: "main", title: "Все преприятия" },
        component: () => import("../components/Global-views/All-companies.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { layout: "empty", title: "Выход" },
    component: () => import("../views/Login.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


// контролер перехода страниц
router.beforeEach((to, from, next) => {

  let token = localStorage.getItem("token");
  document.title = to.meta.title;
  // проверяем - если токена нет, и станица не логин или восстановление , то переходим на логин
  if (to.name !== "Login" && !token) next({ name: "Login" });
  else if (to.name === "Login" && token) {
    next({ name: "Main" });
  }
    // else if (to.name === 'Logout' && token) {
    //   localStorage.removeItem('token');
    //   next({ name: 'Login' });
  // }
  else next();
});

export default router;
