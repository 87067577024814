import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import store from './store';

import './axios'
import './styles/index.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import defaultAxios from './api/axiosInstance'

Vue.use(Buefy,{
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: 'sm',
        'is-small': '1x',
        'is-medium': '2x',
        'is-large': '3x',
      },
      iconPrefix: ""
    },
  }
});

library.add(fas,faHatWizard)
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.prototype.$axios = defaultAxios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  defaultIconComponent: 'vue-fontawesome',
  render: h => h(App)
}).$mount('#app')
