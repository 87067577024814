<template>
    <div class="page">
        <router-view/>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.page{
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, #B8C7E6 0%, #E6EAF2 61px, #E6EAF2 100%);
}
</style>
