<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div
      class="navbar-brand"
      :class="[ isActive ? '' : 'is-flex is-justify-content-space-between is-align-self-flex-end']"
    >
      <div class="logo">
        <img src="@/assets/IDM.png" width="70" />
        <span class="left-side-line"></span>
      </div>
      <!-- <a  role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"> -->
      <router-link class="navbar-burger pt-4" @click="isActive = !isActive" to="/logout" tag="div">
        <font-awesome-icon
          class="exit-icon"
          :icon="['fas', 'fa-arrow-right-from-bracket']"
        ></font-awesome-icon>
      </router-link>
    </div>
    <div
      id="navbarBasicExample"
      class="navbar-menu"
      :class="[isActive ? 'is-active' : '']"
    >
      <div class="navbar-start ml-3">
        <p class="navbar-item is-size-4 has-text-weight-bold has-text-black" :class="[isActive ? 'is-hidden' : '']">
          {{ GetNavName }}
        </p>
      </div>

      <div class="navbar-end mr-3">
        <div class="navbar-item">
          <div class="buttons">
            <!-- <font-awesome-icon
              v-if="GetLoginAccess.data.user.role_id == 3"
              class="exit-icon ml-3"
              :icon="['fas', 'fa-bell']"
            ></font-awesome-icon> -->
            <router-link to="/logout">
              <font-awesome-icon
                class="exit-icon"
                :icon="['fas', 'fa-arrow-right-from-bracket']"
              ></font-awesome-icon>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isActive: false,
    };
  },
  computed: mapGetters(["GetNavName", "GetLoginAccess"]),
  methods: {
    logout() {
      localStorage.removeItem('token')
      this.$router.push("Login")

    }
  }
};
</script>

<style>
.is-hidden {
  display: none;
}

.exit-icon{
  width: 25px;
  height: 25px;
}

@media (min-width: 1024px) {
  .exit-icon {
    font-size: 35px !important;
    color: black;
  }
  .logo{
    position: relative;
    padding-top:10px ;
    padding-left: 10px;
  }
  .left-side-line{
    position: absolute;
    top:15px;
    right:-10px;
    height: 45px;
    width: .5px;
    background-color:#b3b3b3;
  }
}

</style>
